.odules__NewFeaturedModelsWithFilters__styles_ts-wrapper-wrsxgt9{width:1160px;margin:40px auto 20px;}.odules__NewFeaturedModelsWithFilters__styles_ts-wrapper-wrsxgt9 .details-link{font-family:monospace;color:#195aff;font-size:0.8125rem;}
.odules__NewFeaturedModelsWithFilters__styles_ts-loadMoreContainer-lv0mwoz .load-more-btn{margin:2rem auto;}
.odules__NewFeaturedModelsWithFilters__styles_ts-headingContainer-h1tyqcjy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:8px;}
.odules__NewFeaturedModelsWithFilters__styles_ts-heading-hqamlqb{display:inline-block;font-weight:500;font-size:1.5rem;line-height:2rem;color:#101828;margin-right:8px;margin-bottom:16px;}
.odules__NewFeaturedModelsWithFilters__styles_ts-tooltipContentCls-t151troi{display:inline-block;padding:8px;line-height:0;}
.odules__NewFeaturedModelsWithFilters__styles_ts-chevronIconButton-c1jx6kbh{display:inline-block;margin-right:8px;}
.odules__NewFeaturedModelsWithFilters__styles_ts-chevronIconTooltip-cr8lpt5{display:inline-block;vertical-align:top;}.odules__NewFeaturedModelsWithFilters__styles_ts-chevronIconTooltip-cr8lpt5 button{width:32px;height:32px;}
.odules__NewFeaturedModelsWithFilters__styles_ts-chevronIcon-cu8h8cf{color:#344054;}
.odules__NewFeaturedModelsWithFilters__styles_ts-tooltipCls-t17ai5px{display:inline-block;border-radius:1rem;padding:0.2rem 0.5rem;margin-top:-1rem;background-color:rgba(23,31,41);}.odules__NewFeaturedModelsWithFilters__styles_ts-tooltipCls-t17ai5px + .darkArrow{margin-top:-0.8px;}.odules__NewFeaturedModelsWithFilters__styles_ts-tooltipCls-t17ai5px + .darkArrow svg{fill:rgba(23,31,41) !important;stroke:rgba(23,31,41);}
.odules__NewFeaturedModelsWithFilters__styles_ts-sorterWrapper-slsyo6t{display:inline-block;margin-left:auto;z-index:2;}.odules__NewFeaturedModelsWithFilters__styles_ts-sorterWrapper-slsyo6t .container{font-size:14px;height:40px;width:150px;color:rgb(52,64,84);border:1px solid rgb(228,231,236);border-radius:8px;padding:10px 16px;-webkit-flex:1 1 0%;-ms-flex:1 1 0%;flex:1 1 0%;}@media (min-width:1024px){.odules__NewFeaturedModelsWithFilters__styles_ts-sorterWrapper-slsyo6t .container{width:200px;}}@media (max-width:1023.98px){.odules__NewFeaturedModelsWithFilters__styles_ts-sorterWrapper-slsyo6t .container{font-size:1rem;padding:10px 10px;width:125px;}}.odules__NewFeaturedModelsWithFilters__styles_ts-sorterWrapper-slsyo6t .title{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:left;gap:0.3rem;}.odules__NewFeaturedModelsWithFilters__styles_ts-sorterWrapper-slsyo6t .title:hover{opacity:0.7;}.odules__NewFeaturedModelsWithFilters__styles_ts-sorterWrapper-slsyo6t .title .sortByLabel{-webkit-flex:1;-ms-flex:1;flex:1;}@media (max-width:1023.98px){.odules__NewFeaturedModelsWithFilters__styles_ts-sorterWrapper-slsyo6t .title .sortByLabel span{display:none;}}@media (max-width:1023.98px){.odules__NewFeaturedModelsWithFilters__styles_ts-sorterWrapper-slsyo6t{-webkit-flex:1;-ms-flex:1;flex:1;}}
.odules__NewFeaturedModelsWithFilters__styles_ts-starBadge-suqb1in{border:1px solid #dfe3ea;border-radius:0 0 0 8px;}
.odules__NewFeaturedModelsWithFilters__styles_ts-cardCls-cx638z9 .body{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;}
